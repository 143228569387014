const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaTreinamentos: [],
	listaAreas: [],
	listaItens: [],
	listaTipoPergunta: [],
	listaModeloRelatorio: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CHECKLIST_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CHECKLIST_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'CHECKLIST_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CHECKLIST_TREINAMENTO_LISTADO':
			return {
				...state,
				listaTreinamentos: action.payload.data
			};

		case 'CHECKLIST_AREA_LISTADO':
            return {
				...state,
				listaAreas: action.payload.data
			};

		case 'CHECKLIST_PERGUNTA_LISTADO':
            return {
				...state,
				listaItens: action.payload.data
			};

		case 'CHECKLIST_PERGUNTA_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipoPergunta: action.payload.data
			};

		case 'CHECKLIST_MODELO_RELATORIO_SELECT_LISTADO':
			return {
				...state,
				listaModeloRelatorio: action.payload.data
			};

        default:
            return state;
    }
}
