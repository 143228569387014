import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CHECKLIST_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CHECKLIST_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('checklistForm', registro)
    ];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/checklist`);
	return {
		type: 'CHECKLIST_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/checklist`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(initForm({
					...registro,
					id: resp.data.id
				}));
				dispatch(getListaTreinamento(resp.data.id));
				dispatch(getListaArea(resp.data.id));
				dispatch(getListaPergunta(resp.data.id));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/checklist`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/checklist?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/checklist/listarSelect`);
    return {
        type: 'CHECKLIST_GRUPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelectGeral`);
    return {
        type: 'CHECKLIST_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaModeloRelatorio() {
    const request = axios.get(`${consts.API_URL}/modeloRelatorio/listarSelect`);
    return {
        type: 'CHECKLIST_MODELO_RELATORIO_SELECT_LISTADO',
        payload: request
    };
}

export function importar(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		let listaChecklist = [];
		let listaEndereco = [];

		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			let checklist = {
				id_petroshow: String(item[2] || '').trim(),
				razao_social: String(item[3] || '').trim().substring(0, 299).trim(),
				nome_fantasia: String(item[4] || '').trim().substring(0, 299).trim(),
				cnpj: String(item[5] || '').trim().length == 14 ? item[5] : null,
				cpf: String(item[5] || '').trim().length == 14 ? null : item[5],
				id_tipo: String(item[5] || '').trim().length == 14 ? 2 : 1,
				inscricao_estadual: item[6],
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			};

			let endereco = {
				id_petroshow: null,
				id_petroshow_checklist: item[2],
				id_checklist: null,
				codigo_vendedor: item[8],
				endereco: String(item[16] || '').trim().substring(0, 299).trim(),
				bairro: '',
				numero: String(item[18] || '').trim().substring(0, 149).trim(),
				id_cidade: null,
				telefone: FormatUtils.removerMascara(String(item[10] || '')).trim().substring(0, 11).trim(),
				celular: FormatUtils.removerMascara(String(item[12] || '')).trim().substring(0, 11).trim(),
				latitude: String(item[19] || '').trim().substring(0, 19).trim(),
				longitude: String(item[20] || '').trim().substring(0, 19).trim(),
				croqui: null,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}

			if (!listaChecklist.filter(cli => cli.id_petroshow == checklist.id_petroshow).length && checklist.id_petroshow 
					&& checklist.razao_social && checklist.nome_fantasia && (checklist.cpf || checklist.cnpj) && endereco.endereco) {
				listaChecklist.push(checklist);
			}
			if (endereco.endereco) {
				listaEndereco.push(endereco);		
			}
			
		}

		await axios.post(`${consts.API_URL}/checklist/importar`, {
			lista: listaChecklist
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});

		await axios.post(`${consts.API_URL}/endereco/importar`, {
			lista: listaEndereco
		}).then(resp => {
		}).catch(e => {
			setErroAPI(e);
		});

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setAguardando(false));
    };
}

/******* CADASTRO DE TREINAMENTOS *******/

export function initFormTreinamento(registro = {}) {
    return [
        initialize('checklistTreinamentoForm', registro)
    ];
}

export function getListaTreinamento(idChecklist) {
	const request = axios.get(`${consts.API_URL}/checklistTreinamento?id_checklist=${idChecklist}`);
	return {
		type: 'CHECKLIST_TREINAMENTO_LISTADO',
		payload: request
	};
}

export function salvarTreinamento(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/checklistTreinamento`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaTreinamento(registro.id_checklist));
				dispatch(initFormTreinamento(null));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/checklistTreinamento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaTreinamento(registro.id_checklist));
				dispatch(initFormTreinamento(null));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirTreinamento(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/checklistTreinamento?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaTreinamento(registro.id_checklist));
			dispatch(initFormTreinamento(null));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

/******* CADASTRO DE ÁREAS *******/

export function initFormArea(registro = {}) {
    return [
        initialize('checklistAreaForm', registro)
    ];
}

export function getListaArea(idChecklist) {
	const request = axios.get(`${consts.API_URL}/checklistArea?id_checklist=${idChecklist}`);
	return {
		type: 'CHECKLIST_AREA_LISTADO',
		payload: request
	};
}

export function salvarArea(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/checklistArea`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaArea(registro.id_checklist));
				dispatch(initFormArea(null));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/checklistArea`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaArea(registro.id_checklist));
				dispatch(initFormArea(null));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirArea(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/checklistArea?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaArea(registro.id_checklist));
			dispatch(initFormArea(null));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

/******* CADASTRO DE PERGUNTAS *******/

export function initFormPergunta(registro = {}) {
    return [
        initialize('checklistPerguntaForm', registro)
    ];
}

export function getListaPergunta(idChecklist) {
	const request = axios.get(`${consts.API_URL}/checklistItem?id_checklist=${idChecklist}`);
	return {
		type: 'CHECKLIST_PERGUNTA_LISTADO',
		payload: request
	};
}

export function salvarPergunta(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/checklistItem`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaPergunta(registro.id_checklist));
				dispatch(initFormPergunta(null));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/checklistItem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaPergunta(registro.id_checklist));
				dispatch(initFormPergunta(null));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirPergunta(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/checklistItem?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaPergunta(registro.id_checklist));
			dispatch(initFormPergunta(null));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipoPergunta() {
    const request = axios.get(`${consts.API_URL}/checklistTipoPergunta/listarSelect`);
    return {
        type: 'CHECKLIST_PERGUNTA_TIPO_SELECT_LISTADO',
        payload: request
    };
}