import React from 'react'

export default props => (
	<button type={props.submit ? 'submit' : 'button'} className={`btn btn-block btn-lg btn-${props.type}`} onClick={props.event} disabled={props.disabled}>
		{props.text}
	</button>
)

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
