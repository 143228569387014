import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import consts from '../consts';

import {
	setModoTela, initForm
} from './equipamentoActions';

class EquipamentoForm extends Component {

	state = {

	}

	componentWillMount() {

		let initSeletorImagem = () => {
			const fileSelectorImagem = document.createElement('input');
			fileSelectorImagem.setAttribute('type', 'file');
			fileSelectorImagem.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.imagens || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							imagens: lista
						});

						initSeletorImagem();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagem = fileSelectorImagem;

		}

		initSeletorImagem();
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
								
							<Field
								name='montante'
								component={LabelAndInputNumber}
								label='Montantes' placeholder='Informe a quantidade de montantes'
								cols='12 6 4 3'
								readOnly={readOnly}
								casas={0} />
							
							<Field
								name='chapa'
								component={LabelAndInputNumber}
								label='Chapas' placeholder='Informe a quantidade de chapas'
								cols='12 6 4 3'
								readOnly={readOnly}
								casas={0} />

						</Row>

						<Row>
							<Grid cols='12 12 12 12'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Arquivos</label>
									</Grid>
									{(this.props.formularioValues.imagens || []).map(arquivo => (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={arquivo.arquivo_base64 ?true : false}
													onClick={() => {
														window.open(`${consts.URL}/${arquivo.arquivo}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															imagens: this.props.formularioValues.imagens.filter(item => item != arquivo)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorImagem.click()}>
											<img src={pdfAdd} style={{ height: 30 }} />
										</span>
									) : null}
								</Row>

							</Grid>
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }


}

EquipamentoForm = reduxForm({form: 'equipamentoForm', destroyOnUnmount: false})(EquipamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('equipamentoForm')(state),
	registro: state.equipamento.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EquipamentoForm);
