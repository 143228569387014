import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import consts from '../consts';

import {
	setModoTela, initForm, getListaArea, getListaPergunta, salvarCheklistResposta, salvarCheklistStatus
} from './checklistRespostaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndRadioButtom from '../common/form/labelAndRadioButtom';
import LabelAndTextarea from '../common/form/labelAndTextarea';

class ChecklistRespostaForm extends Component {

	state = {
		posicaoArea: 0,
		funcaoSalvarFile: () => {}
	}

    componentWillMount() {

		let initImageSeletor = () => {
			const imageSelector = document.createElement('input');
			imageSelector.setAttribute('type', 'file');
			imageSelector.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						this.state.funcaoSalvarFile(reader.result);

						initImageSeletor();

					}

					reader.readAsDataURL(file);

				}
			});

			this.imageSelector = imageSelector;

		}

		initImageSeletor();

		let initFileSeletor = () => {
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						this.state.funcaoSalvarFile(reader.result);

						initFileSeletor();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelector = fileSelector;

		}

		initFileSeletor();
	}

	componentDidMount = () => {

		let checklistResposta = this.props.formularioValues || {};
		this.props.getListaArea(checklistResposta.id_checklist);
		this.props.getListaPergunta(checklistResposta.id_checklist);

		this.setState({
			...this.state,
			checklistResposta: { ...checklistResposta }
		});
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		// const usuarioLogado = this.props.usuarioLogado || {};
		// let checklist = this.props.route.params.checklist || {};
		
		let { listaArea, listaPergunta } = this.props;

		let anterior = false;
		let proximo = false;

		const styles = {
			scrolldivInicial: {
				flexGrow: 1,
				justifyContent: 'center',
				padding: 40
			},
			textMensagemInicial: {
				textAlign: 'center',
				fontSize: 16,
				marginBottom: 20
			},
			touchableOpacityCard: {
				marginTop: 8
			},
			viewArea: {
				borderWidth: 2,
				borderColor: '#f57215',
				borderRadius: 4,
				marginBottom: 12
			},
			viewAreaHeader: {
				padding: 8
			},
			viewSubAreaHeader: {
				padding: 8,
				backgroundColor: '#f59551'
			},
			viewAreaHeaderdiv: {
				padding: 8,
				fontSize: 16,
				fontWeight: 'bold'
			},
			viewPerguntas: {
				padding: 8
			},
			viewPerguntaHeader: {
				fontSize: 18,
				fontWeight: 'bold',
				marginTop: 8
			},
			textInputData: {
				width: 150,
				
			},
			viewButtons: {
				padding: 16
			},
			viewButtonAcoes: {
				display: 'flex',
				flexDirection: 'row',
				flex: 2,
				marginBottom: 40
			},
			buttonAcao: {
				flex: 1,
				marginTop: 12,
				marginRight: 4,
				marginLeft: 4
			},
		};

		const colors = {
			background: '#fff',
			backgroundFont: '#1e0805',
			primary: '#231f20',
			primaryDark: '#030303',
			primaryFont: '#fff',
			accent: '#f57215',
			accentLight: '#fa9e5c',
			accentFont: '#fff',
			font: '#03260f',
			danger: '#d32f2f'
		};
		const font = {
			color: colors.font,
			fontSize: 16
		};
		const viewAccent = {
			backgroundColor: colors.accent
		};
		const fontAccent = {
			color: colors.accentFont
		};
		const textInput = {
			color: colors.backgroundFont,
			backgroundColor: colors.background,
			marginBottom: 12
		};
		const buttonCadastroAcaoPrimary = {
			flex: 2,
			backgroundColor: colors.primary,
			color: colors.font,
			marginTop: 12,
			marginRight: 4
		};
		
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						{listaArea.filter((area, i) => i == this.state.posicaoArea).map(area => {

							anterior = area.id != listaArea[0].id;
							proximo = area.id != listaArea[listaArea.length - 1].id;

							const subArea = area.id_pai != null;
							
							return (
								<div key={area.id} style={{ ...styles.viewArea }}>
									<div style={!subArea ? { ...viewAccent, ...styles.viewAreaHeader } : { ...viewAccent, ...styles.viewSubAreaHeader }}>
										{subArea &&
										<div style={{ ...fontAccent, ...styles.viewAreaHeaderdiv, fontSize: 12 }}>Subárea</div>}

										<div style={{ ...fontAccent, ...styles.viewAreaHeaderdiv }}>{area.nome}</div>										
									</div>

									<div style={{ ...styles.viewPerguntas }}>
										{listaPergunta.filter(pergunta => pergunta.id_area == area.id).filter(pergunta => {
											return (this.state.checklistResposta ? this.state.checklistResposta.itens || [] : []).filter(itemResposta => itemResposta.id_item == pergunta.id).length;
										}).map(pergunta => {

											let resposta = (this.state.checklistResposta ? this.state.checklistResposta.itens : []).filter(itemResposta => itemResposta.id_item == pergunta.id)[0];

											let respostaPai = (this.state.checklistResposta ? this.state.checklistResposta.itens : []).filter(itemResposta => itemResposta.id_item == pergunta.id_pai)[0];

											if (pergunta.id_pai && (!respostaPai || pergunta.pai_condicao != respostaPai.resposta)) {
												return null;
											}

											let atualizarSimples = (value) => {

												this.setState({
													...this.state,
													checklistResposta: {
														...this.state.checklistResposta,
														itens: this.state.checklistResposta.itens.map(item => {
															if (item.id == resposta.id) {
																return {
																	...item,
																	resposta: value
																}
															} else {
																return item;
															}
														})
													}
												});
											}

											let atualizarMulti = (value) => {	

												this.setState({
													...this.state,
													checklistResposta: {
														...this.state.checklistResposta,
														itens: this.state.checklistResposta.itens.map(item => {
															if (item.id == resposta.id) {

																let respostaAux = (resposta.resposta || []).map(respAux => respAux);
																if (respostaAux.includes(value)) {
																	respostaAux = respostaAux.filter(opcao => opcao != value);
																} else {
																	respostaAux.push(value);
																}

																return {
																	...item,
																	resposta: respostaAux
																}
															} else {
																return item;
															}
														})
													}
												});
											}

											return (
												<div key={pergunta.id}>
													<div style={{ }}>
														<div style={{ ...font, ...styles.viewPerguntaHeader }}>{pergunta.descricao} {pergunta.obrigatorio ? '*' : ''}</div>
													</div>

													{pergunta.id_tipo == 1 ? ( //text Curto
														<div>
															<LabelAndInput
																mode='outlined'
																placeholder='Digite aqui...'
																value={resposta.resposta}
																style={textInput}
																onChange={text => {
																	atualizarSimples(text.target.value);
																}} />
														</div>
													) : (pergunta.id_tipo == 2 ? ( //texto Longo
														<div>
															<LabelAndTextarea
																mode='outlined'
																placeholder='Digite aqui...'
																value={resposta.resposta}
																style={textInput}
																rows={3}
																onChange={text => {
																	atualizarSimples(text.target.value);
																}} />
														</div>
													) : (pergunta.id_tipo == 3 ? ( //Data
														<div>
															
															<LabelAndInput
																mode='outlined'
																placeholder='Digite aqui...'
																value={resposta.resposta}
																style={textInput}
																widthComponent={150}
																type={'date'}
																onChange={text => {
																	atualizarSimples(text.target.value);
																}} />
														</div>
													) : (pergunta.id_tipo == 4 ? ( //Numérica
														<div>
															<LabelAndInputNumber
																mode='outlined'
																placeholder='Digite aqui...'
																keyboardType='numeric'
																value={(resposta.resposta || '').replace('.', ',')}
																style={textInput}
																casas={2}
																onChange={text => {
																	atualizarSimples((text.target.value || '').replace(',', '.'));
																}} />
														</div>
													) : (pergunta.id_tipo == 5 ? ( //Lista de Seleção Única
														<div style={{ marginTop: 4 }}>
															{(pergunta.opcoes || []).map(opcao => (
															<LabelAndRadioButtom key={opcao.id}
																style={{ font }}
																marginBottomComponent={0}
																label={opcao.nome}
																value={opcao.id == resposta.resposta}
																onChange={() => atualizarSimples(opcao.id)} />
															))}
														</div>
													) : (pergunta.id_tipo == 6 ? ( //Lista de Seleção Múltipla
														<div style={{ marginTop: 4 }}>
															{(pergunta.opcoes || []).map(opcao => (
															<LabelAndCheckbox key={opcao.id}
																style={{ font }}
																marginBottomComponent={0}
																label={opcao.nome}
																value={(resposta.resposta || []).includes(opcao.id)}
																onChange={() => atualizarMulti(opcao.id)} />
															))}
														</div>
													) : (pergunta.id_tipo == 7 ? ( //Avaliativa
														<div style={{ marginTop: 4 }}>
															{[
																{ id: 'pessimo', nome: 'Péssimo' },
																{ id: 'ruim', nome: 'Ruim' },
																{ id: 'bom', nome: 'Bom' },
																{ id: 'otimo', nome: 'Ótimo' }
															].map(opcao => (
															<LabelAndRadioButtom key={opcao.id}
																style={{ font }}
																marginBottomComponent={0}
																label={opcao.nome}
																value={opcao.id == resposta.resposta}
																onChange={() => atualizarSimples(opcao.id)} />
															))}
														</div>
													) : (pergunta.id_tipo == 9 ? ( //Pergunta com dependentes
														<div style={{ marginTop: 4 }}>
															{[
																{ id: 'Sim', nome: 'Sim' },
																{ id: 'Não', nome: 'Não' }
															].map(opcao => (
															<LabelAndRadioButtom key={opcao.id}
																style={{ font }}
																marginBottomComponent={0}
																label={opcao.nome}
																value={opcao.id == resposta.resposta}
																onChange={() => atualizarSimples(opcao.id)} />
															))}
														</div>
													 ) : null)))))))}

													{this.componenteComentario(resposta, pergunta)}
													{this.componenteFoto(resposta, pergunta)}
													{this.componenteAnexo(resposta, pergunta)}

												</div>
												
											);
										})}
									</div>
								</div>
							)
						})}

					</ContentCardBody>
					<ContentCardFooter>

						<Row alignCenter>
							{anterior && (
								<Grid cols='6 6 6 6'>
									<Button 
										type={'primary'}
										text={'Anterior'}
										style={{ ...buttonCadastroAcaoPrimary, ...styles.buttonAcao }}
										event={() => {
											if (this.validarRespostas(this.props.listaArea.filter((area, i) => i == this.state.posicaoArea)[0])) {
												this.salvarRespostas(this.props.listaArea.filter((area, i) => i == this.state.posicaoArea)[0]);
												this.setState({
													...this.state,
													posicaoArea: this.state.posicaoArea - 1
												});
											}
										}} />
								</Grid>
							)}
							{proximo ? (
								<Grid cols='6 6 6 6'>
									<Button
										type={'primary'}
										text={'Próxima'}
										style={{ ...buttonCadastroAcaoPrimary, ...styles.buttonAcao }}
										event={() => {
											if (this.validarRespostas(this.props.listaArea.filter((area, i) => i == this.state.posicaoArea)[0])) {
												this.salvarRespostas(this.props.listaArea.filter((area, i) => i == this.state.posicaoArea)[0]);
												this.setState({
													...this.state,
													posicaoArea: this.state.posicaoArea + 1
												});
											}
										}} />
								</Grid>
							) : (
								<Grid cols='6 6 6 6'>
									<Button 
										type={'success'} 
										text={'Salvar'}
										style={{ ...buttonCadastroAcaoPrimary, ...styles.buttonAcao }}
										event={() => {
											if (this.validarRespostas(this.props.listaArea.filter((area, i) => i == this.state.posicaoArea)[0])) {
												this.salvarRespostas(this.props.listaArea.filter((area, i) => i == this.state.posicaoArea)[0], true);
												this.props.setModoTela('lista')
											}
										}} />
								</Grid>
							)}
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }

	componenteComentario(resposta, pergunta) {

		const colors = {
			background: '#fff',
			backgroundFont: '#1e0805',
			primary: '#231f20',
			primaryDark: '#030303',
			primaryFont: '#fff',
			accent: '#f57215',
			accentLight: '#fa9e5c',
			accentFont: '#fff',
			font: '#03260f',
			danger: '#d32f2f'
		};
		const font = {
			color: colors.font,
			fontSize: 16
		};
		const textInput = {
			color: colors.backgroundFont,
			backgroundColor: colors.background,
			marginBottom: 12
		};

		if (!pergunta || !pergunta.complemento_comentario) {
			return null;
		}

		return (
			<div>
				<div style={{ ...font }}>Comentário</div>
				<LabelAndTextarea
					mode='outlined'
					placeholder='Digite aqui...'
					value={resposta.complemento_comentario}
					style={textInput}
					rows={3}
					theme={{
						colors: {
							placeholder: textInput.color,
							text: textInput.color,
							primary: textInput.color,
							underlineColor: textInput.color,
							background: textInput.backgroundColor
						}
					}}
					onChange={text => {
						this.setState({
							...this.state,
							checklistResposta: {
								...this.state.checklistResposta,
								itens: this.state.checklistResposta.itens.map(item => {
									if (item.id == resposta.id) {
										return {
											...item,
											complemento_comentario: text.target.value
										}
									} else {
										return item;
									}
								})
							}
						});
					}} />
			</div>
		);

	}

	componenteFoto(resposta, pergunta) {

		const colors = {
			background: '#fff',
			backgroundFont: '#1e0805',
			primary: '#231f20',
			primaryDark: '#030303',
			primaryFont: '#fff',
			accent: '#f57215',
			accentLight: '#fa9e5c',
			accentFont: '#fff',
			font: '#03260f',
			danger: '#d32f2f'
		};
		const font = {
			color: colors.font,
			fontSize: 16
		};
		const textInput = {
			color: colors.backgroundFont,
			backgroundColor: colors.background,
			marginBottom: 12
		};
		const buttonCadastroExcluir = {
			flex: 2,
			backgroundColor: colors.danger,
			color: colors.font,
			marginTop: 12
		};
			
		if (!pergunta || !pergunta.complemento_foto) {
			return null;
		}

		return (
			<div>
				<div style={{ ...font }}>Foto</div>

				{resposta.complemento_foto ? (
					<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#d9d9d9', padding: 8, width: 166 }}>
						<img
							style={{ width: 150 }}
							src={resposta.complemento_foto} />

						<button 
							type='button' 
							class='btn btn-danger btn-block btn-sm'
							style={{ 
								width: 150,
							    marginLeft: 0,
								marginTop: 8
							}}
							onClick={() => {
								this.setState({
									...this.state,
									checklistResposta: {
										...this.state.checklistResposta,
										itens: this.state.checklistResposta.itens.map(item => {
											if (item.id == resposta.id) {
												return {
													...item,
													complemento_foto: null
												}
											} else {
												return item;
											}
										})
									}
								});
							}}>
							<i class='fa fa-times'></i> Remover
						</button>
					</div>
				) : (
					<div>
						<a class='btn btn-app bg-success'
							onClick={() => {
								this.setState({ 
									...this.state, 
									funcaoSalvarFile: (imagem) => {
										this.setState({
											...this.state,
											checklistResposta: {
												...this.state.checklistResposta,
												itens: this.state.checklistResposta.itens.map(item => {
													if (item.id == resposta.id) {
														return {
															...item,
															complemento_foto: imagem
														}
													} else {
														return item;
													}
												})
											},
											eventoAbrirCamera: null
										});
									} 
								});
								this.imageSelector.click()
							}}>
							<i class='fas fa-camera'></i> Adicionar
						</a>
					</div>
				)}

			</div>
		);

	}

	componenteAnexo(resposta, pergunta) {

		const colors = {
			background: '#fff',
			backgroundFont: '#1e0805',
			primary: '#231f20',
			primaryDark: '#030303',
			primaryFont: '#fff',
			accent: '#f57215',
			accentLight: '#fa9e5c',
			accentFont: '#fff',
			font: '#03260f',
			danger: '#d32f2f'
		};
		const font = {
			color: colors.font,
			fontSize: 16
		};
		const textInput = {
			color: colors.backgroundFont,
			backgroundColor: colors.background,
			marginBottom: 12
		};
		const buttonCadastroExcluir = {
			flex: 2,
			backgroundColor: colors.danger,
			color: colors.font,
			marginTop: 12
		};

		if (!pergunta || !pergunta.complemento_anexo) {
			return null;
		}

		return (
			<div>
				<div style={{ ...font }}>Anexo</div>

				{resposta.complemento_anexo ? (
					<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#d9d9d9', padding: 8, width: 166 }}>
						<img
							style={{ width: 150 }}
							src={resposta.complemento_anexo} />

						<button 
							type='button' 
							class='btn btn-danger btn-block btn-sm'
							style={{ 
								width: 150,
								marginLeft: 0,
								marginTop: 8
							}}
							onClick={() => {
								this.setState({
									...this.state,
									checklistResposta: {
										...this.state.checklistResposta,
										itens: this.state.checklistResposta.itens.map(item => {
											if (item.id == resposta.id) {
												return {
													...item,
													complemento_anexo: null
												}
											} else {
												return item;
											}
										})
									}
								});
							}}>
							<i class='fa fa-times'></i> Remover
						</button>
					</div>
				) : (
					<div>
						<a class='btn btn-app bg-success'
							onClick={() => {
								this.setState({ 
									...this.state, 
									funcaoSalvarFile: (imagem) => {
										this.setState({
											...this.state,
											checklistResposta: {
												...this.state.checklistResposta,
												itens: this.state.checklistResposta.itens.map(item => {
													if (item.id == resposta.id) {
														return {
															...item,
															complemento_anexo: imagem
														}
													} else {
														return item;
													}
												})
											},
											eventoAbrirCamera: null
										});
									} 
								});
								this.fileSelector.click()
							}}>
							<i class='fas fa-paperclip'></i> Adicionar
						</a>
					</div>
				)}

			</div>
		);

	}

	validarRespostas(area) {

		let erroObrigatorio = false;
		this.props.listaPergunta.filter(pergunta => pergunta.id_area == area.id).forEach(pergunta => {
			
			let resposta = (this.state.checklistResposta ? this.state.checklistResposta.itens : []).filter(itemResposta => itemResposta.id_item == pergunta.id)[0];
			
			if (resposta && !erroObrigatorio && pergunta.obrigatorio && !pergunta.id_pai) {
				if (pergunta.id_tipo == 1) { //testo Curto
					erroObrigatorio = (resposta.resposta || '').length <= 0;					
				} else if (pergunta.id_tipo == 2) { //testo Longo
					erroObrigatorio = (resposta.resposta || '').length <= 0;
				} else if (pergunta.id_tipo == 3) { //Data
					erroObrigatorio = (resposta.resposta || '').length <= 0;
				} else if (pergunta.id_tipo == 4) { //Numérica
					erroObrigatorio = (resposta.resposta || '').length <= 0;
				} else if (pergunta.id_tipo == 5) { //Lista de Seleção Única
					erroObrigatorio = (resposta.resposta || '').length <= 0;
				} else if (pergunta.id_tipo == 6) { //Lista de Seleção Múltipla
					erroObrigatorio = (resposta.resposta || []).length <= 0;
				} else if (pergunta.id_tipo == 7) { //Avaliativa
					erroObrigatorio = (resposta.resposta || '').length <= 0;
				} else if (pergunta.id_tipo == 9) { //Pergunta com Dependentes
					erroObrigatorio = (resposta.resposta || '').length <= 0;
				}
			}
		});

		if (erroObrigatorio) {
			
			setError('Responda as perguntas obrigatórias (*)');
			
		}

		return !erroObrigatorio;

	}

	salvarRespostas(area, ultima) {
		
		this.props.listaPergunta.filter(pergunta => pergunta.id_area == area.id).forEach(pergunta => {
			
			let resposta = (this.state.checklistResposta ? this.state.checklistResposta.itens : []).filter(itemResposta => itemResposta.id_item == pergunta.id)[0];

			this.props.salvarCheklistResposta(resposta);
		});

		if (ultima) {
			this.props.salvarCheklistStatus({
				...this.state.checklistResposta,
				id_status: 3
			});
		}
													
	}

}

ChecklistRespostaForm = reduxForm({form: 'checklistRespostaForm', destroyOnUnmount: false})(ChecklistRespostaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('checklistRespostaForm')(state),
	listaArea: state.checklistResposta.listaArea,
	listaPergunta: state.checklistResposta.listaPergunta
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, getListaArea, getListaPergunta, salvarCheklistResposta, salvarCheklistStatus
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistRespostaForm);
