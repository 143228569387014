const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaRealizado: [],
	listaArea: [],
	listaPergunta: [],
	listaCliente: [],
	listaUsuario: [],
	listaGrupo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CHECKLIST_RESPOSTA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CHECKLIST_RESPOSTA_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'CHECKLIST_RESPOSTA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CHECKLIST_RESPOSTA_REALIZADO_LISTADO':
            return {
				...state,
				listaRealizado: action.payload.data
			};

		case 'CHECKLIST_RESPOSTA_AREA_LISTADO':
            return {
				...state,
				listaArea: action.payload.data
			};

		case 'CHECKLIST_RESPOSTA_PERGUNTA_LISTADO':
            return {
				...state,
				listaPergunta: action.payload.data
			};

		case 'CHECKLIST_RESPOSTA_GRUPO_SELECT_LISTADO':
            return {
				...state,
				listaGrupo: action.payload.data
			};

		case 'CHECKLIST_RESPOSTA_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'CHECKLIST_RESPOSTA_USUARIO_SELECT_LISTADO':
            return {
				...state,
				listaUsuario: action.payload.data
			};

        default:
            return state;
    }
}
