import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'CLIENTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'CLIENTE_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('clienteForm', registro)
    ];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/cliente`);
	return {
		type: 'CLIENTE_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/cliente`, {
				...registro,
				cpf_cnpj: FormatUtils.removerMascara(registro.cpf_cnpj),
				cep: FormatUtils.removerMascara(registro.cep),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/cliente`, {
				...registro,
				cpf_cnpj: FormatUtils.removerMascara(registro.cpf_cnpj),
				cep: FormatUtils.removerMascara(registro.cep),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/cliente?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/grupoCliente/listarSelect`);
    return {
        type: 'CLIENTE_GRUPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarSelectGeral`);
    return {
        type: 'CLIENTE_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function importar(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));
		
		let listaCidade = [];
		await axios.get(`${consts.API_URL}/cidade/listarSelectGeral`).then(result => {
			listaCidade = result.data;
		});
		
		let listaGrupo = [];
		await axios.get(`${consts.API_URL}/grupoCliente/listarSelect`).then(result => {
			listaGrupo = result.data;
		});

		let listaGruposImportar = [];
		for (var i = 1; i < tabela.length; i++) {
			let item = tabela[i];

			if (!listaGruposImportar.includes(String(String(item[5] || '').trim())) 
				&& !listaGrupo.filter(grupo => grupo.valor == String(String(item[5] || '').trim())).length) {
				listaGruposImportar.push(String(String(item[5] || '').trim()));
				
				await axios.post(`${consts.API_URL}/grupoCliente`, {
					nome: (String(String(item[5] || '').trim())),
					id_usuario_inclusao: getState().auth.usuario.id,
					id_usuario_alteracao: getState().auth.usuario.id
				});
			}
		}

		await axios.get(`${consts.API_URL}/grupoCliente/listarSelect`).then(result => {
			listaGrupo = result.data;
		});

		let listaCliente = [];
		await axios.get(`${consts.API_URL}/cliente`).then(result => {
			listaCliente = result.data;
		});

		let listaClientesImportar = [];
		for (var i = 1; i < tabela.length; i++) {
			let item = tabela[i];

			let cliente = {

				id_integracao: String(String(item[0] || '').trim()),
				nome: String(String(item[2] || '').trim()),
				cpf_cnpj: FormatUtils.removerMascara(String(String(item[8] || '').trim()) || String(String(item[9] || '').trim())),
				id_grupo: listaGrupo.filter(grupo => grupo.valor.toUpperCase() == String(String(item[5] || '').trim()))[0] 
						? listaGrupo.filter(grupo => grupo.valor.toUpperCase() == String(String(item[5] || '').trim()))[0].id : null,
				id_cidade: listaCidade.filter(cidade => cidade.valor.toUpperCase() == String(String(item[3] || '').trim()))[0] 
						? listaCidade.filter(cidade => cidade.valor.toUpperCase() == String(String(item[3] || '').trim()))[0].id : null,
				endereco: String(String(item[6] || '').trim()),
				numero: '',
				bairro: String(String(item[7] || '').trim()),
				cep: FormatUtils.removerMascara(String(String(item[4] || '').trim())),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			};

			if (cliente.id_integracao 
				&& !listaClientesImportar.includes(cliente.id_integracao) 
				&& !listaCliente.filter(clienteAux => clienteAux.id_integracao == cliente.id_integracao).length) {
					listaClientesImportar.push(cliente.id_integracao);
				
				await axios.post(`${consts.API_URL}/cliente`, {
					...cliente,
					id_usuario_inclusao: getState().auth.usuario.id,
					id_usuario_alteracao: getState().auth.usuario.id
				}).then(resp => {
					console.log(`Incluido ${i+ 1} de ${tabela.length}`);
				}).catch(e => {
					console.log(e);
				});
			}
						
		}

		setSuccess('Importação Realizada com sucesso.');
		dispatch(getLista());
		dispatch(setAguardando(false));
    };
}
