import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import consts from '../consts';
import ChecklistTreinamentoForm from './checklistTreinamentoForm';
import ChecklistAreaForm from './checklistAreaForm';
import ChecklistPerguntaForm from './checklistPerguntaForm';

import {
	setModoTela, initForm,
	initFormTreinamento, getListaTreinamento, salvarTreinamento, excluirTreinamento,
	initFormArea, getListaArea, salvarArea, excluirArea,
	initFormPergunta, getListaPergunta, salvarPergunta, excluirPergunta
} from './checklistActions';
import { CardHeader } from '@material-ui/core';
import ButtonTable from '../common/table/buttonTable';

class ChecklistForm extends Component {

	state = {

	}

	componentWillMount() {
		this.props.getListaTreinamento(this.props.formularioValues.id || 0);
		this.props.getListaArea(this.props.formularioValues.id || 0);
		this.props.getListaPergunta(this.props.formularioValues.id || 0);
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		
		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome' placeholder='Informe o nome'
									cols='12 12 12 12'
									readOnly={readOnly} />
								
								<Select
									name='perfil_aplicador'
									label='Perfis que aplicam' placeholder='Informe'
									options={this.props.listaUsuarioPerfil}
									multiple
									cols='12 12 12 12'
									readOnly={readOnly}
									value={(this.props.formularioValues.perfil_aplicador || [])}
									onChange={value => {
										this.props.initForm({
											...this.props.formularioValues,
											perfil_aplicador: (value || []).map(item => parseInt(item.value))
										});
									}} />

								<Field
									name='id_modelo_relatorio'
									component={Select}
									options={this.props.listaModeloRelatorio}
									label='Modelo de Relatório Padrão'
									cols='12 12 12 12'
									placeholder='Selecione'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='configuracao_aplica_sem_agendamento'
									component={LabelAndCheckbox}
									label='Pode ser aplicado sem agendamento' placeholder='Informe'
									cols='12 4 4 4'
									readOnly={readOnly} />
								
								<Field
									name='configuracao_aprovar_agendamento'
									component={LabelAndCheckbox}
									label='Necessita aprovação do agendamento' placeholder='Informe'
									cols='12 4 4 4'
									readOnly={readOnly} />
									
								<Field
									name='configuracao_cliente_aplica'
									component={LabelAndCheckbox}
									label='Cliente pode aplicar' placeholder='Informe'
									cols='12 4 4 4'
									readOnly={readOnly} />
																
								<Field
									name='configuracao_checklist_analise'
									component={LabelAndCheckbox}
									label='Obrigatório analisar checklist aplicado' placeholder='Informe'
									cols='12 4 4 4'
									readOnly={readOnly} />
										
								<Field
									name='configuracao_reabrir'
									component={LabelAndCheckbox}
									label='Permite reabertura' placeholder='Informe'
									cols='12 4 4 4'
									readOnly={readOnly} />
							</Row>

							<Row>

								<Field
									name='configuracao_quantidade_assinatura'
									component={Select}
									label='Quantidade de Assinaturas' placeholder='Informe'
									options={[{ id: 0, valor: 'Nenhuma assinatura' }, { id: 1, valor: '1 assinatura' }, { id: 2, valor: '2 assinaturas' }, { id: 3, valor: '3 assinaturas' }, { id: 4, valor: '4 assinaturas' }]}
									cols='12 6 4 4'
									readOnly={readOnly} />
										
								<Field
									name='configuracao_quantidade_assinatura_obrigatoria'
									component={Select}
									label='Quantidade de Assinaturas Obrigatórias' placeholder='Informe'
									options={[{ id: 0, valor: 'Nenhuma assinatura' }, { id: 1, valor: '1 assinatura' }, { id: 2, valor: '2 assinaturas' }, { id: 3, valor: '3 assinaturas' }, { id: 4, valor: '4 assinaturas' }]}
									cols='12 6 4 4'
									readOnly={readOnly} />

							</Row>

							<Row>
								<Grid cols='12 12 12 12'>							
									<h3 class='card-title' style={{ marginTop: 8, marginBottom: 16 }}><i class='fas fa-bell'></i>Notificações</h3>
								</Grid>							
							</Row>

							<Row>
																
								<Field
									name='notificacao_agendamento'
									component={LabelAndCheckbox}
									label='Notificar ao agendar' placeholder='Informe'
									cols='12 4 4 3'
									readOnly={readOnly} />
									
								<Field
									name='notificacao_inicio'
									component={LabelAndCheckbox}
									label='Notificar quando iniciar' placeholder='Informe'
									cols='12 4 4 3'
									readOnly={readOnly} />
								
								<Field
									name='notificacao_termino'
									component={LabelAndCheckbox}
									label='Notificar quando terminar' placeholder='Informe'
									cols='12 4 4 3'
									readOnly={readOnly} />
									
								<Field
									name='notificacao_reabertura'
									component={LabelAndCheckbox}
									label='Notificar quando reabrir' placeholder='Informe'
									cols='12 4 4 3'
									readOnly={readOnly} />
							</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ? this.treinamentos(readOnly) : null}

				{this.props.formularioValues.id ? this.areas(readOnly) : null}

			</>
		);
    }

	/****** TREINAMENTO ******/

	treinamentos(readOnly) {

		let listaTreinamentos = this.props.listaTreinamentos;

		return (
			<div>
				{this.modalTreinamento()}
				
				<ContentCard>
					<ContentCardHeader>
						<h3 class='card-title' style={{ fontWeight: 'bold' }}>Treinamentos</h3>
					</ContentCardHeader>
					<ContentCardBody>
						<Row>
							{!readOnly &&
							<Grid cols='12 14 3 3' style={{ marginBottom: 16 }}>
								<Button
									text='Adicionar Treinamento'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.setDialogTreinamento({ id_checklist: this.props.formularioValues.id, ordem: listaTreinamentos.length + 1 });
									}} />
							</Grid>}
						</Row>

						<Row>
							{listaTreinamentos.map(treinamento => {
								return (	
									<Grid cols='12 6 4 4' key={treinamento.id}>
										<div class='callout callout-info'>
											<button type='button' class='close' data-dismiss='alert' aria-hidden='true' 
												onClick={() => this.setDialogTreinamento({ ...treinamento }, true)}>×</button>
											<h6>{treinamento.titulo}</h6>
											<a href={treinamento.link_video} target='_blank'><p>{treinamento.link_video}</p></a>
										</div>
									</Grid>
								);
							})}
						</Row>
					</ContentCardBody>
				</ContentCard>
			</div>
		);
	}

	setDialogTreinamento(registro, excluir) {
		this.props.initFormTreinamento({ ...registro, excluir: excluir });
	}
	
	closeDialogTreinamento() {
		this.props.initFormTreinamento(null);
	}

	modalTreinamento() {

		if (!this.props.formularioTreinamentoValues) {
			return null;
		}

		const registroTreinamento = this.props.formularioTreinamentoValues;

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{registroTreinamento.excluir ? 'Remover Treinamento' : (registroTreinamento.id ? 'Alterar Treinamento' : 'Novo Treinamento')}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.closeDialogTreinamento()}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: window.innerHeight * 0.8, overflowY: 'scroll' }}>
							{!registroTreinamento.excluir ? (
								<ChecklistTreinamentoForm onSubmit={this.props.salvarTreinamento} />
							) : (
								<ChecklistTreinamentoForm excluir onSubmit={this.props.excluirTreinamento} />
							)}
						</div>
					</div>
				</div>
			</div>
        );
    }

	/****** ÁREAS ******/

	areas(readOnly) {

		let listaAreas = this.props.listaAreas;

		return (
			<div>
				{this.modalArea()}

				{listaAreas.map(area => {

					const subArea = area.id_pai != null;

					return (			
						<ContentCard key={area.id}>
							<ContentCardHeader style={{ backgroundColor: !subArea ? '#f57215' : '#f59551' }}>
								<div style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}>
									<div>
										{subArea &&
										<h6 style={{ color: '#ffffff', fontWeight: 'bold', fontSize: 12 }}>Subárea</h6>}

										<h3 class='card-title' style={{ color: '#ffffff', fontWeight: 'bold'}}>{area.nome}</h3>
									</div>
									<div>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={!readOnly}
											event={() => {
												this.setDialogArea({ ...area });
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={!readOnly}
											event={() => {
												this.setDialogArea({ ...area }, true);
											}} />
									</div>
								</div>
							</ContentCardHeader>
							<ContentCardBody>
								{this.perguntas(readOnly, area.id, area.id_pai != null)}
							</ContentCardBody>
						</ContentCard>
					);
				})}

				{!readOnly &&
				<Grid cols='12 12 12 12' style={{ marginTop: 16 }}>
					<Button
						text='Adicionar Área'
						type={'primary'}
						icon={'fa fa-plus'}
						event={() => {
							this.setDialogArea({ id_checklist: this.props.formularioValues.id, ordem: listaAreas.length + 1 });
						}} />
				</Grid>}
			</div>
		);
	}

	setDialogArea(registro, excluir) {
		this.props.initFormArea({ ...registro, excluir: excluir });
	}

	closeDialogArea() {
		this.props.initFormArea(null);
	}

	modalArea() {

		if (!this.props.formularioAreaValues) {
			return null;
		}

		const registroArea = this.props.formularioAreaValues;

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						{!registroArea.id_pai ? (
							<div class='modal-header'>
								<h4 class='modal-title'>{registroArea.excluir ? 'Remover Área' : (registroArea.id ? 'Alterar Área' : 'Nova Área')}</h4>
								<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.closeDialogArea()}>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
						) : (
							<div class='modal-header'>
								<h4 class='modal-title'>{registroArea.excluir ? 'Remover Subárea' : (registroArea.id ? 'Alterar Subárea' : 'Nova Subárea')}</h4>
								<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.closeDialogArea()}>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
						)}
						<div class='modal-body' style={{ maxHeight: window.innerHeight * 0.8, overflowY: 'scroll' }}>
							{!registroArea.excluir ? (
								<ChecklistAreaForm onSubmit={this.props.salvarArea} />
							) : (
								<ChecklistAreaForm excluir onSubmit={this.props.excluirArea} />
							)}
						</div>
					</div>
				</div>
			</div>
        );
    }
	
	/****** PERGUNTAS ******/

	perguntas(readOnly, idArea, subArea) {

		let listaItens = this.props.listaItens.filter(pergunta => pergunta.id_area == idArea);

		let listaComDependencia = [];

		return (
			<div>
				{this.modalPergunta()}

				{listaItens.map(pergunta => {

					//Pergunta com dependentes
					if (pergunta.id_tipo == 9) {
						listaComDependencia.push(pergunta);
					}

					return (			
						<ContentCard key={pergunta.id}>
							<ContentCardHeader style={{ backgroundColor: '#231f20' }}>
								<div style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}>
									<div>
										<h3 class='card-title' style={{ color: '#ffffff', fontWeight: 'bold' }}>{pergunta.descricao}</h3>
									</div>
									<div>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={!readOnly}
											event={() => {
												this.setDialogPergunta({ ...pergunta });
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={!readOnly}
											event={() => {
												this.setDialogPergunta({ ...pergunta }, true);
											}} />
									</div>
								</div>
							</ContentCardHeader>
							<ContentCardBody>
								<Row>
									<Grid cols='12 12 12 12'>
										<p><b>Tipo:</b> {pergunta.nome_tipo}</p>
									</Grid>
								</Row>
								{pergunta.opcoes.length > 0 &&
								<Row>
									<Grid cols='12 12 12 12'>
										<p style={{ fontWeight: 'bold' }}>Opções:</p>
									</Grid>
									{pergunta.opcoes.map(opcao => (
										<Grid key={opcao.id} cols='12 12 12 12'>
											<div class='callout callout-info' style={{ padding: 4, marginBottom: 4 }}>
												<p>{opcao.nome}</p>
											</div>
										</Grid>
									))}
								</Row>}
								<Row style={{ marginTop: 8 }}>
									<Grid cols='12 12 12 12'>
										<p><b>Obrigatória:</b> {pergunta.obrigatorio ? 'Sim' : 'Não'}</p>
									</Grid>
								</Row>
								<Row>
									<Grid cols='12 12 12 12'>
										<p style={{ fontWeight: 'bold' }}>Complementos</p>
									</Grid>
									<a class={`btn btn-app bg-${pergunta.complemento_comentario ? 'success' : 'secondary'}`} >
										<i class='fas fa-barcode'></i> Comentário
									</a>
									<a class={`btn btn-app bg-${pergunta.complemento_anexo ? 'success' : 'secondary'}`} >
										<i class='fas fa-barcode'></i> Anexo
									</a>
									<a class={`btn btn-app bg-${pergunta.complemento_foto ? 'success' : 'secondary'}`} >
										<i class='fas fa-barcode'></i> Foto
									</a>
									<a class={`btn btn-app bg-${pergunta.complemento_assinatura ? 'success' : 'secondary'}`} >
										<i class='fas fa-barcode'></i> Assinaura
									</a>
								</Row>			
								
							</ContentCardBody>
						</ContentCard>
					);
				})}

				{!readOnly &&
				<Row>				
					<Grid cols={!subArea ? '6 6 6 6' : '12 12 12 12'}>
						<Button
							text='Adicionar Pergunta'
							type={'primary'}
							icon={'fa fa-plus'}
							event={() => {
								this.setDialogPergunta({ id_checklist: this.props.formularioValues.id, id_area: idArea, ordem: listaItens.length + 1, opcoes: [], listaComDependencia });
							}} />
					</Grid>
					{!subArea &&
					<Grid cols='6 6 6 6'>
						<Button
							text='Adicionar Subárea'
							type={'primary'}
							icon={'fa fa-plus'}
							event={() => {
								this.setDialogArea({ id_checklist: this.props.formularioValues.id, id_pai: idArea, ordem: this.props.listaAreas.length + 1 });
							}} />
					</Grid>}
				</Row>}
			</div>
		);
	}

	setDialogPergunta(registro, excluir) {
		this.props.initFormPergunta({ ...registro, excluir: excluir });
	}

	closeDialogPergunta() {
		this.props.initFormPergunta(null);
	}

	modalPergunta() {

		if (!this.props.formularioPerguntaValues) {
			return null;
		}

		const registroPergunta = this.props.formularioPerguntaValues;

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{registroPergunta.excluir ? 'Remover Pergunta' : (registroPergunta.id ? 'Alterar Pergunta' : 'Nova Pergunta')}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.closeDialogPergunta()}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: window.innerHeight * 0.8, overflowY: 'scroll' }}>
							{!registroPergunta.excluir ? (
								<ChecklistPerguntaForm onSubmit={this.props.salvarPergunta} />
							) : (
								<ChecklistPerguntaForm excluir onSubmit={this.props.excluirPergunta} />
							)}
						</div>
					</div>
				</div>
			</div>
        );
    }

}

ChecklistForm = reduxForm({form: 'checklistForm', destroyOnUnmount: false})(ChecklistForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('checklistForm')(state),
	registro: state.checklist.registro,
	listaUsuarioPerfil: state.usuarioPerfil.listaSelect,
	listaTreinamentos: state.checklist.listaTreinamentos,
	listaModeloRelatorio: state.checklist.listaModeloRelatorio,
	formularioTreinamentoValues: getFormValues('checklistTreinamentoForm')(state),
	listaAreas: state.checklist.listaAreas,
	formularioAreaValues: getFormValues('checklistAreaForm')(state),
	listaItens: state.checklist.listaItens,
	formularioPerguntaValues: getFormValues('checklistPerguntaForm')(state),
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm,
	initFormTreinamento, getListaTreinamento, salvarTreinamento, excluirTreinamento,
	initFormArea, getListaArea, salvarArea, excluirArea,
	initFormPergunta, getListaPergunta, salvarPergunta, excluirPergunta
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistForm);
