import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import consts from '../consts';

import {
	initFormPergunta as initForm
} from './checklistActions';
import ButtonTable from '../common/table/buttonTable';

class ChecklistPerguntaForm extends Component {

	state = {

	}

	componentWillMount() {

	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		
		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='descricao'
									component={LabelAndInput}
									label='Descrição' placeholder='Informe a descrição'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								<Field
									name='id_tipo'
									component={Select}
									label='Tipo' placeholder='Informe o tipo'
									options={this.props.listaTipoPergunta}
									cols='12 12 12 12'
									readOnly={readOnly} />

								{(this.props.formularioValues.listaComDependencia || []).length > 0 &&
								<Field
									name='id_pai'
									component={Select}
									label='Dependência' placeholder='Informe se possui dependência'
									options={this.props.formularioValues.listaComDependencia.map(item => ({ id: item.id, valor: item.descricao}))}
									cols='12 12 12 12'
									readOnly={readOnly} />}
									
								{this.props.formularioValues.id_pai &&
								<Field
									name='pai_condicao'
									component={Select}
									label='Condição' placeholder='Informe a condição esperada'
									options={[{ id: 'Sim', valor: 'Sim' },{ id: 'Não', valor: 'Não' }]}
									cols='12 12 12 12'
									readOnly={readOnly} />}

							</Row>

							{(this.props.formularioValues.id_tipo == 5 || this.props.formularioValues.id_tipo == 6) && //Lista de Seleção Única e Lista de Seleção Múltipla
							<Row>
								{this.props.formularioValues.opcoes.map((opcao, i) => (
									<Grid key={opcao.id} cols='12 12 12 12'>
										<div
											style={{
												display: 'flex',
												justifyContent: 'start',
												alignItems: 'center'
											}}>
											<LabelAndInput
												label={`Opção ${i+1}`} placeholder='Informe'
												cols='11 11 11 11'
												readOnly={readOnly}
												value={opcao.nome}
												onChange={data => {
													this.props.initForm({
														...this.props.formularioValues,
														opcoes: this.props.formularioValues.opcoes.map(item => ({
															...item,
															nome: item.id == opcao.id ? data.target.value : item.nome
														}))
													});
												}} />

											<ButtonTable
												type={'danger'}
												marginTop={16}
												icon={'fas fa-trash-alt'}
												visible={!readOnly}
												event={() => {
													let lista = this.props.formularioValues.opcoes.map(item => ({ ...item })).filter(item => item.id != opcao.id);

													this.props.initForm({
														...this.props.formularioValues,
														opcoes: lista
													});
												}} />
										</div>
									</Grid>
								))}
							</Row>}

							{(this.props.formularioValues.id_tipo == 5 || this.props.formularioValues.id_tipo == 6) && //Lista de Seleção Única e Lista de Seleção Múltipla
							<Row style={{ marginTop: 12, marginBottom: 16 }}>
								<Grid cols='12 12 6 4'>
									<Button
										text='Adicionar Opção'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => {
											let lista = this.props.formularioValues.opcoes.map(opcao => ({ ...opcao }));

											lista.push({
												id: new Date().getTime(),
												nome: ''
											});

											this.props.initForm({
												...this.props.formularioValues,
												opcoes: lista
											});
										}} />
								</Grid>
							</Row>}
							
							<Row>
								{this.props.formularioValues.id_tipo == 8 && //Seleção Interativa (Silo)
								<Field
									name='id_equipamento'
									component={Select}
									label='Equipamento' placeholder='Informe o equipamento'
									options={this.props.listaEquipamento}
									cols='12 12 12 12'
									readOnly={readOnly} />}

								<Field
									name='obrigatorio'
									component={LabelAndCheckbox}
									label='Obrigatória' placeholder='Informe'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>

							{this.props.formularioValues.id_tipo != 9 && //Pergunta com dependentes
							<Row>
								<Grid cols='12 12 12 12'>
									<p style={{ fontWeight: 'bold' }}>Complementos</p>
								</Grid>
								<a class={`btn btn-app bg-${this.props.formularioValues.complemento_comentario ? 'success' : 'secondary'}`}
									onClick={() => this.props.initForm({ ...this.props.formularioValues, complemento_comentario: !this.props.formularioValues.complemento_comentario })}>
									<i class='fas fa-barcode'></i> Comentário
								</a>
								<a class={`btn btn-app bg-${this.props.formularioValues.complemento_anexo ? 'success' : 'secondary'}`}
									onClick={() => this.props.initForm({ ...this.props.formularioValues, complemento_anexo: !this.props.formularioValues.complemento_anexo })}>
									<i class='fas fa-barcode'></i> Anexo
								</a>
								<a class={`btn btn-app bg-${this.props.formularioValues.complemento_foto ? 'success' : 'secondary'}`}
									onClick={() => this.props.initForm({ ...this.props.formularioValues, complemento_foto: !this.props.formularioValues.complemento_foto })}>
									<i class='fas fa-barcode'></i> Foto
								</a>
								<a class={`btn btn-app bg-${this.props.formularioValues.complemento_assinatura ? 'success' : 'secondary'}`}
									onClick={() => this.props.initForm({ ...this.props.formularioValues, complemento_assinatura: !this.props.formularioValues.complemento_assinatura })}>
									<i class='fas fa-barcode'></i> Assinaura
								</a>
							</Row>}

							<div style={{ height: 100 }}></div>
	
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.initForm(null)} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

			</>
		);
    }

}

ChecklistPerguntaForm = reduxForm({form: 'checklistPerguntaForm', destroyOnUnmount: false})(ChecklistPerguntaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('checklistPerguntaForm')(state),
	registro: state.checklist.registro,
	listaTipoPergunta: state.checklist.listaTipoPergunta,
	listaEquipamento: state.equipamento.listaSelect
});
const mapDispatchToProps = dispatch => bindActionCreators({
	initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistPerguntaForm);
