import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import consts from '../consts';

import {
	setModoTela, initForm
} from './modeloRelatorioActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ModeloRelatorioForm extends Component {

	state = {

	}

    componentWillMount() {

		let initSeletorImagemCapa = () => {
			const fileSelectorImagemCapa = document.createElement('input');
			fileSelectorImagemCapa.setAttribute('type', 'file');
			fileSelectorImagemCapa.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_capa: reader.result
						});

						initSeletorImagemCapa();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagemCapa = fileSelectorImagemCapa;

		}

		initSeletorImagemCapa();

		let initSeletorImagemCabecalho = () => {
			const fileSelectorImagemCabecalho = document.createElement('input');
			fileSelectorImagemCabecalho.setAttribute('type', 'file');
			fileSelectorImagemCabecalho.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_cabecalho: reader.result
						});

						initSeletorImagemCabecalho();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagemCabecalho = fileSelectorImagemCabecalho;

		}

		initSeletorImagemCabecalho();

		let initSeletorImagemRodape = () => {
			const fileSelectorImagemRodape = document.createElement('input');
			fileSelectorImagemRodape.setAttribute('type', 'file');
			fileSelectorImagemRodape.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_rodape: reader.result
						});

						initSeletorImagemRodape();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagemRodape = fileSelectorImagemRodape;

		}

		initSeletorImagemRodape();

		let initSeletorImagemFolhaContatos = () => {
			const fileSelectorImagemFolhaContatos = document.createElement('input');
			fileSelectorImagemFolhaContatos.setAttribute('type', 'file');
			fileSelectorImagemFolhaContatos.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						this.props.initForm({
							...this.props.formularioValues,
							imagem_folha_contatos: reader.result
						});

						initSeletorImagemFolhaContatos();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagemFolhaContatos = fileSelectorImagemFolhaContatos;

		}

		initSeletorImagemFolhaContatos();
	}

    render() {

		let readOnly = this.props.endereco || this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='titulo'
								component={LabelAndInput}
								label='Título' placeholder='Informe o título'
								cols='12 12 12 12'
								readOnly={readOnly} />
															
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Imagem da Capa (1024 X 1518)</label>
									</Grid>
									{this.props.formularioValues.imagem_capa ? (
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={this.props.formularioValues.imagem_capa} style={{ height: 200 }} />
											</span>
											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: 40, height: 40 }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														imagem_capa: null
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									) : (									
										<span class="btn btn-outline-primary fileinput-button dz-clickable"
											style={{ marginLeft: 10 }}
											onClick={() => this.fileSelectorImagemCapa.click()}>
											<img src={pdfAdd} style={{ height: 40 }} />
										</span>
									)}
								</Row>

							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Imagem do Cabeçalho (1024 X 120)</label>
									</Grid>
									{this.props.formularioValues.imagem_cabecalho ? (
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={this.props.formularioValues.imagem_cabecalho} style={{ height: 80 }} />
											</span>
											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: 40, height: 40 }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														imagem_cabecalho: null
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									) : (									
										<span class="btn btn-outline-primary fileinput-button dz-clickable"
											style={{ marginLeft: 10 }}
											onClick={() => this.fileSelectorImagemCabecalho.click()}>
											<img src={pdfAdd} style={{ height: 40 }} />
										</span>
									)}
								</Row>

							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Imagem do Rodapé (1024 X 60)</label>
									</Grid>
									{this.props.formularioValues.imagem_rodape ? (
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={this.props.formularioValues.imagem_rodape} style={{ height: 40 }} />
											</span>
											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: 40, height: 40 }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														imagem_rodape: null
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									) : (									
										<span class="btn btn-outline-primary fileinput-button dz-clickable" 
											style={{ marginLeft: 10 }}
											onClick={() => this.fileSelectorImagemRodape.click()}>
											<img src={pdfAdd} style={{ height: 40 }} />
										</span>
									)}
								</Row>

							</Grid>
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Imagem da Folha de Contatos (1024 X 1518)</label>
									</Grid>
									{this.props.formularioValues.imagem_folha_contatos ? (
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={this.props.formularioValues.imagem_folha_contatos} style={{ height: 200 }} />
											</span>
											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: 40, height: 40 }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														imagem_folha_contatos: null
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									) : (									
										<span class="btn btn-outline-primary fileinput-button dz-clickable" 
											style={{ marginLeft: 10 }}
											onClick={() => this.fileSelectorImagemFolhaContatos.click()}>
											<img src={pdfAdd} style={{ height: 40 }} />
										</span>
									)}
								</Row>

							</Grid>
						</Row>					

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }


}

ModeloRelatorioForm = reduxForm({form: 'modeloRelatorioForm', destroyOnUnmount: false})(ModeloRelatorioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('modeloRelatorioForm')(state),
	registro: state.modeloRelatorio.registro,
	listaGrupo: state.modeloRelatorio.listaGrupo,
	listaCidade: state.modeloRelatorio.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ModeloRelatorioForm);
