import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import {
	setModoTela, initForm
} from './clienteActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ClienteForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.endereco || this.props.excluir ? 'readOnly' : '';

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 8 8 9'
								readOnly={readOnly} />

							<Field
								name='id_integracao'
								component={LabelAndInput}
								label='ID (Emissor)' placeholder='Informe o ID do Emissor'
								cols='12 4 4 3'
								readOnly={readOnly} />

							<Field
								name='id_tipo'
								component={Select}
								options={[{ id: 1, valor: 'Pessoa Física' }, { id: 2, valor: 'Pessoa Jurídica' }]}
								label='Tipo'
								cols='12 4 4 4'
								placeholder='Selecione'
								readOnly={readOnly} />
							<Field
								name='cpf_cnpj'
								component={LabelAndInputMask}
								label={this.props.formularioValues.id_tipo == 2 ? 'CNPJ' : 'CPF'} placeholder={`Informe o ${this.props.formularioValues.id_tipo == 2 ? 'CNPJ' : 'CPF'}`}
								cols='12 4 4 4'
								readOnly={readOnly}
								mask={this.props.formularioValues.id_tipo == 2 ? '99.999.999/9999-99' : '999.999.999-99'} />

							<Field
								name='inscricao_estadual'
								component={LabelAndInput}
								label='Inscrição Estadual' placeholder='Informe a IE'
								cols='12 4 4 4'
								readOnly={readOnly} />

							<Field
								name='id_grupo'
								component={Select}
								options={this.props.listaGrupo}
								label='Região / Grupo'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />

							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço' placeholder='Informe o endereço'
								cols='12 12 6 6'
								readOnly={readOnly} />

							<Field
								name='numero'
								component={LabelAndInput}
								label='Número' placeholder='Informe o número'
								cols='12 6 3 3'
								readOnly={readOnly} />

							<Field
								name='complemento'
								component={LabelAndInput}
								label='Complemento' placeholder='Informe o complemento'
								cols='12 6 3 3'
								readOnly={readOnly} />

							<Field
								name='bairro'
								component={LabelAndInput}
								label='Bairro' placeholder='Informe o bairro'
								cols='12 6 5 5'
								readOnly={readOnly} />
								
							<Field
								name='cep'
								component={LabelAndInputMask}
								label='CEP' placeholder='Informe o CEP'
								cols='12 6 3 2'
								mask='99999-999'
								readOnly={readOnly} />
								
							<Field
								name='id_cidade'
								component={Select}
								options={this.props.listaCidade}
								label='Cidade'
								cols='12 12 4 5'
								placeholder='Selecione'
								readOnly={readOnly} />								

							<Field
								name='latitude'
								component={LabelAndInput}
								label='Latitude' placeholder='Informe a latitude'
								cols='12 6 4 3'
								readOnly={readOnly} />

							<Field
								name='longitude'
								component={LabelAndInput}
								label='Longitude' placeholder='Informe a longitude'
								cols='12 6 4 3'
								readOnly={readOnly} />
								
							{this.props.formularioValues.id &&
							<Field
								name='inativado'
								component={LabelAndCheckbox}
								label='Inativado' placeholder='Informe'
								cols='12 12 12 12'
								readOnly={readOnly} />}
															
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }


}

ClienteForm = reduxForm({form: 'clienteForm', destroyOnUnmount: false})(ClienteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('clienteForm')(state),
	registro: state.cliente.registro,
	listaGrupo: state.cliente.listaGrupo,
	listaCidade: state.cliente.listaCidade
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteForm);
