const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaModeloRelatorio: [],
	listaChecklistResposta: [],
	listaArea: [],
	listaPergunta: [],
	listaRespostaItem: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'RELATORIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'RELATORIO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'RELATORIO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'RELATORIO_MODELO_RELATORIO_SELECT_LISTADO':
            return {
				...state,
				listaModeloRelatorio: action.payload.data
			};

		case 'RELATORIO_CHECKLIST_RESPOSTA_LISTADO':
            return {
				...state,
				listaChecklistResposta: action.payload.data
			};

		case 'RELATORIO_AREA_LISTADO':
            return {
				...state,
				listaArea: action.payload.data
			};

		case 'RELATORIO_PERGUNTA_LISTADO':
            return {
				...state,
				listaPergunta: action.payload.data
			};

		case 'RELATORIO_RESPOSTA_ITEM_LISTADO':
            return {
				...state,
				listaRespostaItem: action.payload.data
			};

        default:
            return state;
    }
}
