import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setCliente } from '../../auth/authActions';
import DateFormat from '../dateFormat/DateFormat';
import Row from '../layout/row';
import Grid from '../layout/grid';
import LabelAndInput from '../form/labelAndInput';
import Button from '../button/button';
import { carregarUsuario, alterarSenha } from '../../auth/authActions';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			open: false, relogio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
			dadosLogin: {} 
		};
	}
	changeOpen() {
		this.setState({ 
			...this.state,
			open: !this.state.open 
		});
	}

	componentWillMount() {
		setInterval(() => {
			this.setState({
				...this.state,
				relogio: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
			});
		}, 30000)
	}

	render() {
		const { nome, email } = this.props.usuario;

		return (
			<>

				{this.state.alterarSenha ? this.modalAlterarSenha() : null}

				<ul className="navbar-nav ml-auto">
					<li className="nav-item dropdown">
						<a className="nav-link" data-toggle="dropdown" href="#">
							<i className="far fa-clock" style={{ marginRight: 4 }}></i>{this.state.relogio}
						</a>
					</li>
					<li className="nav-item dropdown">
						<a className="nav-link" data-toggle="dropdown" href="#">
							<i className="far fa-user"></i>
						</a>
						<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ minWidth: 350 }}>
							<span className="dropdown-item dropdown-header">Perfil</span>
							<div className="dropdown-divider"></div>
							<a href="#" className="dropdown-item">
								<i className="fas fa-user mr-2"></i> Nome
								<span className="float-right text-muted text-sm">{nome}</span>
							</a>
							<div className="dropdown-divider"></div>
							<a href="#" className="dropdown-item">
								<i className="fas fa-envelope mr-2"></i> E-mail
								<span className="float-right text-muted text-sm">{email}</span>
							</a>
							<div className="dropdown-divider"></div>
							<Row style={{ paddingTop: 1, paddingBottom: 1, marginTop: 8 }}>
								<Grid cols='6 6 6 6' style={{ paddingLeft: 4, paddingRight: 1 }}>
									<button type='button' 
										class='btn btn-secondary btn-block'
										onClick={() => this.setState({ ...this.state, alterarSenha: true, dadosLogin: {} })}>
										<i class='fas fa-user-cog'></i> Conta 
									</button>
								</Grid>
								<Grid cols='6 6 6 6' style={{ paddingLeft: 1, paddingRight: 15 }}>
									<button type='button' 
										class='btn btn-danger btn-block'
										onClick={this.props.logout}>
										<i class="fas fa-sign-out-alt"></i> Sair
									</button>
								</Grid>
							</Row>
						</div>
					</li>
				</ul>
			</>
		);
	}

	modalAlterarSenha() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#231f20', color: '#fff' }}>
							<h4 class='modal-title'>Alterar Senha</h4>
						</div>
						<div class='modal-body' style={{ overflowY: 'scroll' }}>

							<Row alignCenter>
								<LabelAndInput
									name='senha'
									type="password"
									label='Senha *'
									placeholder='Informe a Senha'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosLogin ? this.state.dadosLogin.senha : ''}
									onChange={data => this.setState({ ...this.state, dadosLogin: { ...this.state.dadosLogin, senha: data.target.value }})}  />
							</Row>
							<Row alignCenter>
								<LabelAndInput
									name='senha_confirmacao'
									type="password"
									label='Confirme sua Senha *'
									placeholder='Informe a Confirmação'
									cols='12 12 6 6'
									readOnly={''}
									value={this.state.dadosLogin ? this.state.dadosLogin.senha_confirmacao : ''}
									onChange={data => this.setState({ ...this.state, dadosLogin: { ...this.state.dadosLogin, senha_confirmacao: data.target.value }})} />
							</Row>
							<Row alignCenter>
								<Grid cols='12 12 6 6'>
									<Button
										text='Salvar nova Senha'
										type={'success'}
										icon={'fa fa-chevron-left'}
										disabled={!this.state.dadosLogin.senha || this.state.dadosLogin.senha != this.state.dadosLogin.senha_confirmacao}
										event={() => {
											this.props.alterarSenha({ ...this.state.dadosLogin, id: this.props.usuario.id });
											this.setState({ ...this.state, alterarSenha: false, dadosLogin: {} })
										}} />
								</Grid>
							</Row>

						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout, setCliente, carregarUsuario, alterarSenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className="navbar-custom-menu">
	<ul className="nav navbar-nav">
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href="javascript:;" onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className="dropdown-toggle"
				data-toggle="dropdown">
				<i className="fa fa-user"/>
				<span className="hidden-xs">{nome}</span>
			</a>
			<ul className="dropdown-menu">
				<li className="user-header">
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className="user-footer">
					<div className="pull-right">
						<a href="#" onClick={this.props.logout}
							className="btn btn-default btn-flat">Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
