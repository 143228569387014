import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioPerfil from '../usuarioPerfil/usuarioPerfil';
import Usuario from '../usuario/usuario';
import GrupoCliente from '../grupoCliente/grupoCliente';
import Cliente from '../cliente/cliente';
import Equipamento from '../equipamento/equipamento';
import Checklist from '../checklist/checklist';
import ModeloRelatorio from '../modeloRelatorio/modeloRelatorio';
import Relatorio from '../relatorio/relatorio';
import ChecklistResposta from '../checklistResposta/checklistResposta';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuarioPerfil' component={UsuarioPerfil} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/grupoCliente' component={GrupoCliente} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/equipamento' component={Equipamento} />
			<Route path='/checklist' component={Checklist} />
			<Route path='/modeloRelatorio' component={ModeloRelatorio} />
			<Route path='/relatorio' component={Relatorio} />
			<Route path='/checklistResposta' component={ChecklistResposta} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
