import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './checklistRespostaForm';
import NovoForm from './checklistRespostaNovoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { setModoTela, initForm, getLista, getListaRealizado, iniciarChecklist, continuarChecklist, getListaCliente, getListaUsuario, getListaGrupo } from './checklistRespostaActions';
import DateFormat from '../common/dateFormat/DateFormat';

class ChecklistResposta extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaRealizado();
		this.props.getListaCliente();
		this.props.getListaUsuario();
		this.props.getListaGrupo();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
					{this.props.modoTela == 'novoChecklist' ? (
						<NovoForm />
					) : null}
					{this.props.modoTela == 'agendarChecklist' ? (
						<NovoForm agendamento />
					) : null}
                </Content>
            </div>
        )
    }

	lista() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Iniciar Checklist'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('novoChecklist', {});
									this.props.initForm({});
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Agendar Checklist'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('agendarChecklist', {});
									this.props.initForm({ data_agendamento: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()) });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Data</Th>
								<Th alignCenter>Checklist</Th>
								<Th alignCenter>Cliente</Th>
								<Th alignCenter>Aplicador</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaRealizado.filter(item => this.props.lista.filter(check => check.id == item.id_checklist).length).map(item => {

								const checklist = this.props.lista.filter(check => check.id == item.id_checklist)[0];

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td alignCenter>{item.nome_status}</Td>
										<Td alignCenter>{item.id_status == 1 ? DateFormat.formatarDataSqlParaTela(item.data_agendamento) : DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
										<Td alignCenter>{checklist.nome}</Td>
										<Td alignCenter>{item.nome_cliente}</Td>
										<Td alignCenter>{item.nome_usuario}</Td>
										<Td alignRight minWidth={10}>																			
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.continuarChecklist(
														{ ...item, id_status: 2 }, 
														(checklistResposta) => {
															this.props.initForm({ ...checklistResposta });
															this.props.setModoTela('cadastro', { ...checklistResposta });
														}
													);
												}} />
										</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	novoChecklist() {

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('lista', {});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th width={200}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map(item => {

								return (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome}</Td>
										<Td alignRight minWidth={10}>																			
											<Button
												text='Iniciar Checklist'
												type={'success'}
												icon={'fa fa-plus'}
												event={() => {
													this.props.iniciarChecklist(
														{ ...item, id_status: 2 }, 
														(checklistResposta) =>  {
															this.props.initForm({ ...checklistResposta });
															this.props.setModoTela('cadastro', { ...checklistResposta });
														}
													);
												}} />
										</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.checklistResposta.modoTela,
	aguardando: state.checklistResposta.aguardando,
	lista: state.checklistResposta.lista,
	listaRealizado: state.checklistResposta.listaRealizado
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, getLista, getListaRealizado, iniciarChecklist, continuarChecklist, getListaCliente, getListaUsuario, getListaGrupo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistResposta);