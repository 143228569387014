const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaGrupo: [],
	listaCidade: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'CLIENTE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'CLIENTE_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'CLIENTE_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'CLIENTE_GRUPO_SELECT_LISTADO':
            return {
				...state,
				listaGrupo: action.payload.data
			};

		case 'CLIENTE_CIDADE_SELECT_LISTADO':
            return {
				...state,
				listaCidade: action.payload.data
			};

        default:
            return state;
    }
}
