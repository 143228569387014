const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaSelect: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'EQUIPAMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'EQUIPAMENTO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'EQUIPAMENTO_LISTADO':
            return {
				...state,
				lista: action.payload.data,
				listaSelect: action.payload.data.map(item => ({ id: item.id, valor: item.nome }))
			};
			
        default:
            return state;
    }
}
