import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './relatorioForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { 
	setModoTela, initForm, salvar, excluir, getLista, getListaModeloRelatorio, getListaChecklistResposta,
	getListaArea, getListaPergunta, getListaRespostaItem
} from './relatorioActions';
import DateFormat from '../common/dateFormat/DateFormat';

class Relatorio extends Component {

	state = {
		linhaSelecionada: null,
		paginacaoIndice: 0
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaModeloRelatorio();
		this.props.getListaChecklistResposta();
    }

    render() {
        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		console.log(this.props.lista);
		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome_modelo_relatorio} ${item.nome_checklist} ${item.nome_usuario_inclusao || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}
			return true;
		});

		let paginacao = [];

		if (this.props.lista.length) {
			for (let i = 0; i < this.props.lista.length; i += 500) {				
				paginacao.push({
					inicio: i,
					fim: i + 499
				});				
			}
		} else {
			paginacao.push({
				inicio: 0,
				fim: 499
			});
		}

		return (
			<ContentCard>				
				<ContentCardBody>

					{this.props.listaChecklistResposta.filter((item, i) => {
								return this.props.lista.filter(relatorio => relatorio.id_checklist_resposta == item.id).length == 0
					}).length > 0 &&
					<>
						<h6>Checklists Abertos</h6>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th alignCenter>Checklist</Th>
									<Th alignCenter>Usuário</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaChecklistResposta.filter((item, i) => {
									return this.props.lista.filter(relatorio => relatorio.id_checklist_resposta == item.id).length == 0
								}).map(item => (
									<Tr key={item.id} >
										<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
										<Td alignCenter>{item.nome_checklist}</Td>
										<Td alignCenter>{item.nome_usuario_inclusao}</Td>
										<Td alignRight minWidth={10}>																			
											<ButtonTable
												type={'success'}
												icon={'fas fa-plus'}
												visible={true}
												event={() => {
													this.props.setModoTela('cadastro', {
														id_checklist_resposta: item.id,
														id_checklist: item.id_checklist,
														id_modelo_relatorio: item.id_modelo_relatorio
													});
													this.props.initForm({
														id_checklist_resposta: item.id,
														id_checklist: item.id_checklist,
														id_modelo_relatorio: item.id_modelo_relatorio
													});
													this.props.getListaArea(0);
													this.props.getListaPergunta(0);
													this.props.getListaRespostaItem(0);
												}} />
											</Td>
										</Tr>
									))}
								</TBody>
						</Table>
					</> }
										
					<ul class='pagination pagination-sm m-0 float-right'>
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: this.state.paginacaoIndice > 0 ? this.state.paginacaoIndice - 1 : this.state.paginacaoIndice
								})}>{'<<'}
							</a>
						</li>
						
						{paginacao.map((item, i) => (
							<li class='page-item'>
								<a class='page-link'  
									style={{ 
										fontWeight: 'bold',
										cursor: 'pointer',
										backgroundColor: i == this.state.paginacaoIndice ? '#007bff' : null,
										color: i == this.state.paginacaoIndice ? '#fff' : null
									}}
									onClick={() => this.setState({
										...this.state,
										paginacaoIndice: i
									})}>{i + 1}
								</a>
							</li>
						))}
						
						<li class='page-item'>
							<a class='page-link' 
								style={{ 
									cursor: 'pointer'
								}}
								onClick={() => this.setState({
									...this.state,
									paginacaoIndice: paginacao.length - 1 > this.state.paginacaoIndice ? this.state.paginacaoIndice + 1 : this.state.paginacaoIndice
								})}>{'>>'}
							</a>
						</li>
					</ul>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value, paginacaoIndice: 0 });
						}} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data e Hora</Th>
								<Th alignCenter>Modelo</Th>
								<Th alignCenter>Checklist</Th>
								<Th alignCenter>Usuário</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{lista.filter((item, i) => {
								return i >= paginacao[this.state.paginacaoIndice].inicio && i <= paginacao[this.state.paginacaoIndice].fim;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignCenter>{item.nome_modelo_relatorio}</Td>
									<Td alignCenter>{item.nome_checklist}</Td>
									<Td alignCenter>{item.nome_usuario_inclusao}</Td>
									<Td alignRight minWidth={10}>																			
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
												this.props.getListaArea(item.id_checklist);
												this.props.getListaPergunta(item.id_checklist);
												this.props.getListaRespostaItem(item.id_checklist_resposta);
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.relatorio.modoTela,
	aguardando: state.relatorio.aguardando,
	lista: state.relatorio.lista,
	listaChecklistResposta: state.relatorio.listaChecklistResposta
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, getListaModeloRelatorio, getListaChecklistResposta,
	getListaArea, getListaPergunta, getListaRespostaItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Relatorio);