import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoTela, initForm
} from './grupoClienteActions';

class GrupoClienteForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='id_grupo_pai'
								component={Select}
								options={this.props.lista.filter(item => item.id != this.props.formularioValues.id).map(item => ({ ...item, valor: item.nome }))}
								label='Grupo Pai'
								cols='12 12 12 12'
								placeholder='Selecione o pai'
								readOnly={readOnly} />

						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }


}

GrupoClienteForm = reduxForm({form: 'grupoClienteForm', destroyOnUnmount: false})(GrupoClienteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('grupoClienteForm')(state),
	registro: state.grupoCliente.registro,
	lista: state.grupoCliente.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GrupoClienteForm);
