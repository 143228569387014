import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import consts from '../consts';

import {
	initFormTreinamento as initForm
} from './checklistActions';

class ChecklistTreinamentoForm extends Component {

	state = {

	}

	componentWillMount() {

	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		
		return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>

								<Field
									name='titulo'
									component={LabelAndInput}
									label='Título' placeholder='Informe o título'
									cols='12 12 12 12'
									readOnly={readOnly} />
									
								<Field
									name='link_video'
									component={LabelAndInput}
									label='Link do Vídeo' placeholder='Informe o link'
									cols='12 12 12 12'
									readOnly={readOnly} />
	
							</Row>

						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.initForm(null)} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

			</>
		);
    }

}

ChecklistTreinamentoForm = reduxForm({form: 'checklistTreinamentoForm', destroyOnUnmount: false})(ChecklistTreinamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('checklistTreinamentoForm')(state),
	registro: state.checklist.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChecklistTreinamentoForm);
