import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioPerfilReducer from '../usuarioPerfil/usuarioPerfilReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import GrupoClienteReducer from '../grupoCliente/grupoClienteReducer';
import ClienteReducer from '../cliente/clienteReducer';
import EquipamentoReducer from '../equipamento/equipamentoReducer';
import ChecklistReducer from '../checklist/checklistReducer';
import ModeloRelatorioReducer from '../modeloRelatorio/modeloRelatorioReducer';
import RelatorioReducer from '../relatorio/relatorioReducer';
import ChecklistRespostaReducer from '../checklistResposta/checklistRespostaReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuarioPerfil: UsuarioPerfilReducer,
	usuario: UsuarioReducer,
	grupoCliente: GrupoClienteReducer,
	cliente: ClienteReducer,
	equipamento: EquipamentoReducer,
	checklist: ChecklistReducer,
	modeloRelatorio: ModeloRelatorioReducer,
	relatorio: RelatorioReducer,
	checklistResposta: ChecklistRespostaReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

