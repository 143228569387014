import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario } from '../../auth/authActions';

class Menu extends Component {

	state = {
		acessoAberto: null,
		cadastroAberto: null
	}

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		// if (this.props.usuarioCarregado) {
		// 	if (!this.props.usuarioCarregado.acesso_usuario && (selecionado == '/usuario' || selecionado == '/usuarioPerfil')) {
		// 		window.location = '/';
		// 	}
		// }

		if (selecionado != '/' && this.state.acessoAberto == null && this.state.cadastroAberto == null
			&& this.state.precoAberto == null && this.state.vendaAberto == null 
			&& this.state.logisticaAberto == null && this.state.metaMotoristaAberto == null 
			&& this.state.metaVendedorAberto == null) {
			if (selecionado == '/usuarioPerfil' || selecionado == '/usuario') {
				this.setState({
					...this.state,
					acessoAberto: true
				});
			}
			
			if (selecionado == '/grupoCliente' || selecionado == '/cliente' || selecionado == '/equipamento' || selecionado == '/checklist' || selecionado == '/modeloRelatorio') {
				this.setState({
					...this.state,
					cadastroAberto: true
				});
			}
		}
		
		
		return (
			<nav className="mt-2">
								    
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					<MenuItem path='/' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/'} />
					
					{true/* || this.props.usuarioCarregado && (
						this.props.usuarioCarregado.acesso_cadastro_usuario
					)*/ &&
					<MenuTree icon={'fas fa-user-lock'} label={'Acesso'}
						display={this.state.acessoAberto}
						onClick={() =>
							this.setState({
								...this.state,
								acessoAberto: !this.state.acessoAberto,
							})
						} >
						<MenuItem submenu path='usuarioPerfil' label='Perfil de Usuário' icon='fas fa-user-tag' active={selecionado == '/usuarioPerfil'} />
						<MenuItem submenu path='usuario' label='Usuário' icon='fas fa-id-card-alt' active={selecionado == '/usuario'} />
					</MenuTree>}

					{true/* || this.props.usuarioCarregado && (
						this.props.usuarioCarregado.acesso_cadastro
					)*/ &&
					<MenuTree icon={'fas fa-cog'} label={'Cadastro'}
						display={this.state.cadastroAberto}
						onClick={() =>
							this.setState({
								...this.state,
								cadastroAberto: !this.state.cadastroAberto,
							})
						} >
						<MenuItem submenu path='grupoCliente' label='Região / Grupo' icon='fas fa-users' active={selecionado == '/grupoCliente'} />
						<MenuItem submenu path='cliente' label='Cliente' icon='fas fa-user-edit' active={selecionado == '/cliente'} />
						<MenuItem submenu path='equipamento' label='Equipamento' icon='fas fa-warehouse' active={selecionado == '/equipamento'} />
						<MenuItem submenu path='checklist' label='Checklist' icon='fas fa-clipboard-check' active={selecionado == '/checklist'} />
						<MenuItem submenu path='modeloRelatorio' label='Modelo de Relatório' icon='fas fa-file' active={selecionado == '/modeloRelatorio'} />
					</MenuTree>}

					<MenuItem path='checklistResposta' label='Checklist - Responder' icon='fas fa-clipboard' active={selecionado == '/checklistResposta'} />

					<MenuItem path='relatorio' label='Relatório' icon='fas fa-file-invoice' active={selecionado == '/relatorio'} />
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
