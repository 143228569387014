import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../common/template/header';
import SideBar from '../common/template/sideBar';
import Footer from '../common/template/footer';
import Messages from '../common/msg/messages';
import imagemLogo from '../assets/images/logo.png';

import Routes from './routes';

class App extends Component {

	state = {
		carregando: true
	}

	constructor(props) {
		super(props);

		setTimeout(() => {
			this.setState({ ...this.state, carregando: false })
		}, 2000);
    }

	render() {
		return (
			<HashRouter>
				{this.state.carregando ?

					<div class='modal fade show' id='modal-overlay' style={{ display: 'block', top: '40%' }} aria-modal='true' role='dialog'>
						<div class='modal-dialog'>
							<div class='overlay'>
								<div style={{ marginLeft: window.screen.width > 767 ? '30%' : '10%', marginRight: '50%' }}>
									<img src={imagemLogo} style={{ width: 300 }} />
								</div>
							</div>
						</div>
					</div>
				:
					<div className='wrapper'>
						<Header />
						<SideBar />
						<Routes />
						<Messages />
						<a href='https://wa.me/555533759800?text=Ol%C3%A1,%20vi%20no%20site%20da%20Kepler%20Weber%20os%20produtos%20e%20servi%C3%A7os,%20gostaria%20de%20saber%20mais!'
							 target='_blank'
							 class='btn btn-success back-to-top' role='button' aria-label=''
							 style={{
								borderRadius: '50%',
								height: 44,
								width: 44,
								padding: 8
							 }}>
							<i class='fab fa-whatsapp' style={{ fontSize: 24 }}></i>
						</a>
					</div>
				}
			</HashRouter>
		);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
export default connect(mapStateToProps, null)(App);
