import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'RELATORIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'RELATORIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('relatorioForm', registro)
    ];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/relatorio`);
	return {
		type: 'RELATORIO_LISTADO',
		payload: request
	};
}

export function getListaArea(idChecklist) {
	const request = axios.get(`${consts.API_URL}/checklistArea?id_checklist=${idChecklist}`);
	return {
		type: 'RELATORIO_AREA_LISTADO',
		payload: request
	};
}

export function getListaPergunta(idChecklist) {
	const request = axios.get(`${consts.API_URL}/checklistItem?id_checklist=${idChecklist}`);
	return {
		type: 'RELATORIO_PERGUNTA_LISTADO',
		payload: request
	};
}

export function getListaRespostaItem(idChecklistResposta) {
	const request = axios.get(`${consts.API_URL}/checklistRespostaItem?id_checklist_resposta=${idChecklistResposta}`);
	return {
		type: 'RELATORIO_RESPOSTA_ITEM_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/relatorio`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(initForm({
					...registro,
					id: resp.data.id
				}));
				dispatch(setModoTela('cadastro'));

				dispatch(getListaArea(registro.id_checklist));
				dispatch(getListaPergunta(registro.id_checklist));
				dispatch(getListaRespostaItem(registro.id_checklist_resposta));

			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			// axios.put(`${consts.API_URL}/relatorio`, {
			// 	...registro,
			// 	id_usuario_alteracao: getState().auth.usuario.id
			// }).then(resp => {
				// setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			// }).catch(e => {
			// 	setErroAPI(e);
			// });
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/relatorio?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaModeloRelatorio() {
    const request = axios.get(`${consts.API_URL}/modeloRelatorio/listarSelect`);
    return {
        type: 'RELATORIO_MODELO_RELATORIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaChecklistResposta() {
    const request = axios.get(`${consts.API_URL}/checklistResposta`);
    return {
        type: 'RELATORIO_CHECKLIST_RESPOSTA_LISTADO',
        payload: request
    };
}

export function gerarPdf(registro) {

	return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/relatorio/gerarPdf`, {
            ...registro
        }).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}
