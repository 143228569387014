import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';

import {
	setModoTela, initForm, gerarPdf
} from './relatorioActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class RelatorioForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.formularioValues.id || this.props.excluir ? 'readOnly' : '';

		let modelo = this.props.listaModeloRelatorio.filter(item => item.id == this.props.formularioValues.id_modelo_relatorio)[0];

		let paginas = [];
		let pagina = {
			numero: 1,
			html: '',
			tamanho: 0
		};

		let adicionarNaPagina = (item, tamanho) => {
			if (pagina.tamanho + tamanho > 700) {
				paginas.push({ ...pagina });

				pagina = {
					numero: pagina.numero + 1,
					html: '',
					tamanho: 0
				};
			}

			pagina = {
				...pagina,
				html: `
					${pagina.html}
					${item}
				`,
				tamanho: pagina.tamanho + tamanho
			};

		}

		let numeroCapitulo = 0;
		let numeroSubArea = 0;

		this.props.listaArea.forEach((area, i) => {

			if (!area.id_pai) {
				numeroCapitulo++;
				numeroSubArea = 0;
			} else {
				numeroSubArea++;
			}

			adicionarNaPagina(`<p style="font-size: 12px; font-weight: bold;${numeroSubArea ? ' font-style: italic' : ''}">${numeroCapitulo}.${numeroSubArea ? `${numeroSubArea}.` : ''} ${area.nome}</p>`, 45);

			this.props.listaPergunta.filter(pergunta => pergunta.id_area == area.id).filter(pergunta => {
				return this.props.listaRespostaItem.filter(itemResposta => itemResposta.id_item == pergunta.id).length;
			}).map((pergunta, j) => {
				
				let resposta = this.props.listaRespostaItem.filter(itemResposta => itemResposta.id_item == pergunta.id)[0];

				let tamanho = 0;

				let respostaDescricao = '';
				if (resposta) {
					if (pergunta.id_tipo == 1) { //Texto Curto
						respostaDescricao = `<p>${resposta.resposta}</p>`;
						tamanho += 45;
					} else if (pergunta.id_tipo == 2) { //Texto Longo
						respostaDescricao = (resposta.resposta || '').split('\n').map(respostaItem => `<p style="padding: 0px;">${respostaItem}</p>`).join('\n');
						tamanho += (resposta.resposta || '').split('\n').length * 45;
					} else if (pergunta.id_tipo == 3) { //Data
						respostaDescricao = `<p>${DateFormat.formatarDataSqlParaTela(resposta.resposta)}</p>`;
						tamanho += 45;
					} else if (pergunta.id_tipo == 4) { //Numérica
						respostaDescricao = `<p>${resposta.resposta}</p>`;
						tamanho += 45;
					} else if (pergunta.id_tipo == 5) { //Lista de Seleção Única
						let opcaoSelecionada = (pergunta.opcoes || []).filter(opcao => opcao.id == resposta.resposta)[0];
						respostaDescricao = `<p>${opcaoSelecionada ? opcaoSelecionada.nome : ''}</p>`;
						tamanho += 45;
					} else if (pergunta.id_tipo == 6) { //Lista de Seleção Múltipla
						respostaDescricao = `<p>${(resposta.resposta || []).map(respostaOpcao => {
							let opcaoSelecionada = (pergunta.opcoes || []).filter(opcao => opcao.id == respostaOpcao)[0];
							return opcaoSelecionada ? opcaoSelecionada.nome : null;
						}).filter(respostaOpcao => respostaOpcao).join(', ')}</p>`;
						tamanho += 45;
					} else if (pergunta.id_tipo == 7) { //Avaliativa
						respostaDescricao = `<p>${resposta.resposta}</p>`;
						tamanho += 45;
					}
				}

				if (resposta.complemento_comentario) {
					tamanho += (resposta.complemento_comentario || '').split('\n').length * 45;
				}

				if (resposta.complemento_foto) {
					tamanho += 220;
				}

				adicionarNaPagina(`
					<p style="font-size: 11px; font-weight: bold; margin-left: 12px; ">${numeroCapitulo}.${numeroSubArea ? `${numeroSubArea}.` : '0.'}${j+1} ${pergunta.descricao}</p>
					<div style="margin-left: 28">
						<div style="font-size: 10px; padding-left: 14px;">
							${respostaDescricao}
													
							${resposta.complemento_comentario ? `
								<p style="font-weight: bold; font-style: italic; padding: 0px;">Comentário:</p>
								${(resposta.complemento_comentario || '').split('\n').map(comentario => `<p style="font-style: 'italic'; padding: 0px;">${comentario}</p>`).join('\n')}
							` : ''}
						</div>
					
						${resposta.complemento_foto ? `
							<div style="margin-bottom: 8px;">
								<img src="${resposta.complemento_foto}" style="height: 200px" />
							</div>
						` : ''}
					</div>
				`, tamanho);
				

			});
		});

		paginas.push({ ...pagina });

		let registro = {
			imagem_capa: modelo ? modelo.imagem_capa : null,
			imagem_cabecalho: modelo ? modelo.imagem_cabecalho : null,
			imagem_rodape: modelo ? modelo.imagem_rodape : null,
			imagem_folha_contatos: modelo ? modelo.imagem_folha_contatos : null,
			paginas: paginas
		};

		numeroCapitulo = 0;
		numeroSubArea = 0;

		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome_checklist'
								component={LabelAndInput}
								label='Checklist' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='id_modelo_relatorio'
								component={Select}
								options={this.props.listaModeloRelatorio}
								label='Modelo'
								cols='12 12 12 12'
								placeholder='Selecione'
								readOnly={readOnly} />
															
						</Row>

						{this.props.listaArea.map((area, i) => {

							if (!area.id_pai) {
								numeroCapitulo++;
								numeroSubArea = 0;
							} else {
								numeroSubArea++;
							}

							return (
								<Row>

									<Grid cols='12 12 12 12'>
										<p style={{ fontSize: 16, fontWeight: 'bold', fontStyle: numeroSubArea ? 'italic' : null }}>{numeroCapitulo}.{numeroSubArea ? `${numeroSubArea}.` : ''} {area.nome}</p>
									</Grid>

									{this.props.listaPergunta.filter(pergunta => pergunta.id_area == area.id).filter(pergunta => {
										return this.props.listaRespostaItem.filter(itemResposta => itemResposta.id_item == pergunta.id).length;
									}).map((pergunta, j) => {
										
										let resposta = this.props.listaRespostaItem.filter(itemResposta => itemResposta.id_item == pergunta.id)[0];

										let respostaDescricao = () => '';
										if (resposta) {
											if (pergunta.id_tipo == 1) { //Texto Curto
												respostaDescricao = () => <p>{resposta.resposta}</p>;
											} else if (pergunta.id_tipo == 2) { //Texto Longo
												respostaDescricao = () => (resposta.resposta || '').split('\n').map(respostaItem => <p style={{ padding: 0 }}>{respostaItem}</p>);
											} else if (pergunta.id_tipo == 3) { //Data
												respostaDescricao = () => <p>{DateFormat.formatarDataSqlParaTela(resposta.resposta)}</p>;
											} else if (pergunta.id_tipo == 4) { //Numérica
												respostaDescricao = () => <p>{resposta.resposta}</p>;
											} else if (pergunta.id_tipo == 5) { //Lista de Seleção Única
												let opcaoSelecionada = (pergunta.opcoes || []).filter(opcao => opcao.id == resposta.resposta)[0];
												respostaDescricao = () => <p>{opcaoSelecionada ? opcaoSelecionada.nome : null}</p>;
											} else if (pergunta.id_tipo == 6) { //Lista de Seleção Múltipla
												respostaDescricao = () => <p>{(resposta.resposta || []).map(respostaOpcao => {
													let opcaoSelecionada = (pergunta.opcoes || []).filter(opcao => opcao.id == respostaOpcao)[0];
													return opcaoSelecionada ? opcaoSelecionada.nome : null;
												}).filter(respostaOpcao => respostaOpcao).join(', ')}</p>;
											} else if (pergunta.id_tipo == 7) { //Avaliativa
												respostaDescricao = () => <p>{resposta.resposta}</p>;
											}
										}

										// complemento_anexo
										// complemento_assinatura
										// complemento_comentario
										// complemento_foto

										return (
											<Grid cols='12 12 12 12'>
												<p style={{ fontSize: 14, fontWeight: 'bold', marginLeft: 12 }}>{numeroCapitulo}.{numeroSubArea ? `${numeroSubArea}.` : '0.'}{j+1} {pergunta.descricao}</p>
												<div style={{ marginLeft: 28}}>
													{respostaDescricao()}

													{(resposta && resposta.complemento_comentario) &&
													<>
														<p style={{ fontWeight: 'bold', fontStyle: 'italic', padding: 0 }}>Comentário:</p>
														{(resposta.complemento_comentario || '').split('\n').map(comentario => <p style={{ fontStyle: 'italic', padding: 0 }}>{comentario}</p>)}
													</>}

													{resposta.complemento_foto && 
													<div style={{ marginBottom: 8 }}>
														<img src={resposta.complemento_foto} style={{ height: 450 }} />
													</div>}
												</div>
											</Grid>
										);

									})}

								</Row>
							);
						})}

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text={this.props.formularioValues.id ? 'OK' : 'Salvar'}
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							{this.props.formularioValues.id &&
							<Grid cols='6 6 4 3'>
								<Button
									text={'Imprimir'}
									type={'primary'}
									icon={'fa fa-check'}
									event={() => this.props.gerarPdf(registro)} />
							</Grid>}
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => window.confirm('Deseja realmente sair dessa tela?') ? this.props.setModoTela('lista') : null} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
    }


}

RelatorioForm = reduxForm({form: 'relatorioForm', destroyOnUnmount: false})(RelatorioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('relatorioForm')(state),
	registro: state.relatorio.registro,
	listaModeloRelatorio: state.relatorio.listaModeloRelatorio,
	listaArea: state.relatorio.listaArea,
	listaPergunta: state.relatorio.listaPergunta,
	listaRespostaItem: state.relatorio.listaRespostaItem
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, gerarPdf
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioForm);
